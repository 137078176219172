<template>
    <div style="padding: 0 20px">
        <div class="big-title">
            <span></span>
            <el-button type="primary" @click="addBtn">添加商品</el-button>
        </div>
        <el-table :data="goodsList" class="customTable" style="width: 100%;">
            <el-table-column label="商品信息" min-width="150">
                <template slot-scope="scope">
                    <div class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.good_images" alt="">
                        </div>
                        <div class="text-overflow-2 goods-name" :title="scope.row.good_name">{{scope.row.good_name}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="sum_trading_money" label="成交金额指数" align="center"></el-table-column>
            <el-table-column prop="sum_trading_order" label="成交单量指数" align="center"></el-table-column>
            <el-table-column prop="sum_followers_number" label="关注人数" align="center"></el-table-column>
            <el-table-column prop="sum_visitor_number" label="访客指数" align="center"></el-table-column>
            <el-table-column prop="sum_search_click_number" label="搜索点击指数" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editBtn(scope.row.good_id)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row.good_id, scope.$index)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

        <div style="text-align: center; margin: 40px 0">
            <el-button @click="goBack">上一步</el-button>
            <el-button type="primary" @click="submitForm">下一步</el-button>
        </div>

        <el-dialog title="添加商品" :visible.sync="dialogVisible" width="500px" custom-class="custom-dialog"
                   :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
            <el-form :model="addGoodsForm" :rules="rules" ref="addGoodsForm" label-width="100px" class="demo-ruleForm" :hide-required-asterisk="true">
                <el-form-item label="商品" prop="good_id">
                    <el-select filterable v-model="addGoodsForm.good_id" placeholder="请选择商品" style="width: 100%" :disabled="goodsListForm.length === 0">
                        <el-option v-for="(item, index) in goodsListForm" :key="`goodsList_${index}`" :label="item.good_name" :value="item.good_id"></el-option>
                    </el-select>
                    <span v-if="goodsListForm.length === 0" style="position: absolute; left: 0; top: 28px; font-size: 12px; color: #ff0000;">第一步商品类目下没有商品</span>
                </el-form-item>
                <el-form-item label="搜索人数" prop="search_number">
                    <el-input-number class="number-input" v-model="addGoodsForm.search_number" :precision="0" :controls="false" :min="1" :max="999999999" style="width: 100%"></el-input-number>
                </el-form-item>
                <div class="flex">
                    <el-form-item label="波动范围(%)"></el-form-item>
                    <el-form-item prop="search_number_range_min" label-width="0">
                        <el-input-number class="number-input" v-model="addGoodsForm.search_number_range_min" :precision="0"
                                         :controls="false" :min="-99" :max="addGoodsForm.search_number_range_max ? addGoodsForm.search_number_range_max - 1 : 99" style="width: 100%"></el-input-number>
                    </el-form-item>
                    <span style="margin: 0 12px; line-height: 40px">~</span>
                    <el-form-item prop="search_number_range_max" label-width="0">
                        <el-input-number class="number-input" v-model="addGoodsForm.search_number_range_max" :precision="0"
                                         :controls="false" :min="addGoodsForm.search_number_range_min ? addGoodsForm.search_number_range_min + 1 : -98" :max="100" style="width: 100%"></el-input-number>
                    </el-form-item>
                </div>

                <el-form-item label="关注人数" prop="followers_number">
                    <el-input-number class="number-input" v-model="addGoodsForm.followers_number" :precision="0" :controls="false" :min="1" :max="999999999" style="width: 100%"></el-input-number>
                </el-form-item>
                <div class="flex">
                    <el-form-item label="波动范围(%)"></el-form-item>
                    <el-form-item prop="followers_number_range_min" label-width="0">
                        <el-input-number class="number-input" v-model="addGoodsForm.followers_number_range_min" :precision="0"
                                         :controls="false" :min="-99" :max="addGoodsForm.followers_number_range_max ? addGoodsForm.followers_number_range_max - 1 : 99" style="width: 100%"></el-input-number>
                    </el-form-item>
                    <span style="margin: 0 12px; line-height: 40px">~</span>
                    <el-form-item prop="followers_number_range_max" label-width="0">
                        <el-input-number class="number-input" v-model="addGoodsForm.followers_number_range_max" :precision="0"
                                         :controls="false" :min="addGoodsForm.followers_number_range_min ? addGoodsForm.followers_number_range_min + 1 : -98" :max="100" style="width: 100%"></el-input-number>
                    </el-form-item>
                </div>

                <el-form-item label="点击率(%)" prop="click_rate">
                    <el-input-number class="number-input" v-model="addGoodsForm.click_rate" :precision="2" :controls="false" :min="0.01" :max="100" style="width: 100%"></el-input-number>
                </el-form-item>
                <div class="flex">
                    <el-form-item label="波动范围(%)"></el-form-item>
                    <el-form-item prop="clicks_rate_range_min" label-width="0">
                        <el-input-number class="number-input" v-model="addGoodsForm.clicks_rate_range_min" :precision="0"
                                         :controls="false" :min="-99" :max="addGoodsForm.clicks_rate_range_max ? addGoodsForm.clicks_rate_range_max - 1 : 99" style="width: 100%"></el-input-number>
                    </el-form-item>
                    <span style="margin: 0 12px; line-height: 40px">~</span>
                    <el-form-item prop="clicks_rate_range_max" label-width="0">
                        <el-input-number class="number-input" v-model="addGoodsForm.clicks_rate_range_max" :precision="0"
                                         :controls="false" :min="addGoodsForm.clicks_rate_range_min ? addGoodsForm.clicks_rate_range_min + 1 : -98" :max="100" style="width: 100%"></el-input-number>
                    </el-form-item>
                </div>

                <el-form-item label="转化率(%)" prop="conversion_rate">
                    <el-input-number class="number-input" v-model="addGoodsForm.conversion_rate" :precision="2" :controls="false" :min="0.01" :max="100" style="width: 100%"></el-input-number>
                </el-form-item>
                <div class="flex">
                    <el-form-item label="波动范围(%)" style="margin-bottom: 0"></el-form-item>
                    <el-form-item prop="conversion_rate_range_min" label-width="0" style="margin-bottom: 0">
                        <el-input-number class="number-input" v-model="addGoodsForm.conversion_rate_range_min" :precision="0"
                                         :controls="false" :min="-99" :max="addGoodsForm.conversion_rate_range_max ? addGoodsForm.conversion_rate_range_max - 1 : 99" style="width: 100%"></el-input-number>
                    </el-form-item>
                    <span style="margin: 0 12px; line-height: 40px">~</span>
                    <el-form-item prop="conversion_rate_range_max" label-width="0" style="margin-bottom: 0">
                        <el-input-number class="number-input" v-model="addGoodsForm.conversion_rate_range_max" :precision="0"
                                         :controls="false" :min="addGoodsForm.conversion_rate_range_min ? addGoodsForm.conversion_rate_range_min + 1 : -98" :max="100" style="width: 100%"></el-input-number>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitGoodsForm('addGoodsForm')" style="margin-left: 30px">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { dataCentorTplGetGoodsDropdown, dataCentorTplAddCommodityData, dataCentorTplCommodityList, dataCentorTplGetCommodityData, dataCentorTplDelCommodity } from '@/utils/apis.js'
    export default {
        data() {
            return {
                goodsList: [],
                // S 弹窗
                dialogVisible: false,
                addGoodsForm: {
                    good_id: null,
                    search_number: void 0, // 搜索人数
                    search_number_range_min: void 0,
                    search_number_range_max: void 0,
                    followers_number: void 0, // 关注人数
                    followers_number_range_min: void 0,
                    followers_number_range_max: void 0,
                    click_rate: void 0, // 点击率
                    clicks_rate_range_min: void 0,
                    clicks_rate_range_max: void 0,
                    conversion_rate: void 0, // 转化率
                    conversion_rate_range_min: void 0,
                    conversion_rate_range_max: void 0,
                },
                rules: {
                    good_id: { required: true, message: '请选择商品', trigger: 'change' },
                    search_number: { required: true, message: '请输入搜索人数', trigger: 'blur' },
                    search_number_range_min: { required: true, message: '请输入波动范围低值', trigger: 'blur' },
                    search_number_range_max: { required: true, message: '请输入波动范围高值', trigger: 'blur' },
                    followers_number: { required: true, message: '请输入关注人数', trigger: 'blur' },
                    followers_number_range_min: { required: true, message: '请输入波动范围低值', trigger: 'blur' },
                    followers_number_range_max: { required: true, message: '请输入波动范围高值', trigger: 'blur' },
                    click_rate: { required: true, message: '请输入点击率', trigger: 'blur' },
                    clicks_rate_range_min: { required: true, message: '请输入波动范围低值', trigger: 'blur' },
                    clicks_rate_range_max: { required: true, message: '请输入波动范围高值', trigger: 'blur' },
                    conversion_rate: { required: true, message: '请输入转化率', trigger: 'blur' },
                    conversion_rate_range_min: { required: true, message: '请输入波动范围低值', trigger: 'blur' },
                    conversion_rate_range_max: { required: true, message: '请输入波动范围高值', trigger: 'blur' },
                },
                goodsListForm: [], // 商品列表
                isEdit: false,
                editGoodId: null,
                // E 弹窗
            }
        },
        mounted() {
            this.getGoodsList()
        },
        methods: {
            getGoodsList() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id
                }
                dataCentorTplCommodityList(params).then((res) => {
                    this.goodsList = res.data.list
                }).catch((err) => {})
            },
            // 弹窗内商品列表
            getGoodsListForm() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id,
                }
                if (this.isEdit) {
                    params.good_id = this.editGoodId
                }
                dataCentorTplGetGoodsDropdown(params).then((res) => {
                    this.goodsListForm = res.data
                }).catch((err) => {})
            },
            // 添加
            addBtn() {
                this.dialogVisible = true
            },
            // 打开弹窗
            openedDialog() {
                this.getGoodsListForm()
            },
            // 关闭弹窗
            closeDialog() {
                this.resetGoodsForm()
                this.goodsListForm = []
                this.isEdit = false
                this.editGoodId = null
            },
            // 编辑商品
            editBtn(id) {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id,
                    good_id: id
                }
                dataCentorTplGetCommodityData(params).then((res) => {
                    this.dialogVisible = true
                    this.addGoodsForm = res.data
                    this.isEdit = true
                    this.editGoodId = id
                }).catch((err) => {})
            },
            // 删除商品
            delBtn(id, index) {
                this.$confirm('是否删除该商品，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box'
                }).then(() => {
                    let formData = new FormData()
                    formData.append('data_center_tpl_id', this.$route.query.data_center_tpl_id)
                    formData.append('good_id', id)
                    dataCentorTplDelCommodity(formData).then((res) => {
                        this.$message.success(res.msg)
                        this.getGoodsList()
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            // 保存商品
            submitGoodsForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('data_center_tpl_id', this.$route.query.data_center_tpl_id)
                        formData.append('good_id', this.addGoodsForm.good_id)
                        formData.append('search_number', this.addGoodsForm.search_number)
                        formData.append('search_number_range_min', this.addGoodsForm.search_number_range_min)
                        formData.append('search_number_range_max', this.addGoodsForm.search_number_range_max)
                        formData.append('followers_number', this.addGoodsForm.followers_number)
                        formData.append('followers_number_range_min', this.addGoodsForm.followers_number_range_min)
                        formData.append('followers_number_range_max', this.addGoodsForm.followers_number_range_max)
                        formData.append('click_rate', this.addGoodsForm.click_rate)
                        formData.append('clicks_rate_range_min', this.addGoodsForm.clicks_rate_range_min)
                        formData.append('clicks_rate_range_max', this.addGoodsForm.clicks_rate_range_max)
                        formData.append('conversion_rate', this.addGoodsForm.conversion_rate)
                        formData.append('conversion_rate_range_min', this.addGoodsForm.conversion_rate_range_min)
                        formData.append('conversion_rate_range_max', this.addGoodsForm.conversion_rate_range_max)
                        dataCentorTplAddCommodityData(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.getGoodsList()
                            this.dialogVisible = false
                        }).catch((err) => {})
                    } else {
                        return false;
                    }
                });
            },
            // 重置商品
            resetGoodsForm() {
                this.$refs.addGoodsForm.resetFields();
                this.addGoodsForm = {
                    good_id: null,
                    search_number: void 0, // 搜索人数
                    search_number_range_min: void 0,
                    search_number_range_max: void 0,
                    followers_number: void 0, // 关注人数
                    followers_number_range_min: void 0,
                    followers_number_range_max: void 0,
                    click_rate: void 0, // 点击率
                    clicks_rate_range_min: void 0,
                    clicks_rate_range_max: void 0,
                    conversion_rate: void 0, // 转化率
                    conversion_rate_range_min: void 0,
                    conversion_rate_range_max: void 0,
                }
            },

            // 上一步
            goBack() {
                this.$router.push({
                    path: '/practice/dataStepTwo',
                    query: {
                        stepActive: 2,
                        goodClassId: Number(this.$route.query.goodClassId),
                        data_center_tpl_id: Number(this.$route.query.data_center_tpl_id),
                        // status: 'edit',
                    }
                })
            },
            // 下一步
            submitForm() {
                if (this.goodsList.length === 0) {
                    return this.$message.warning('请添加商品榜单数据')
                }
                this.$router.push({
                    path: '/practice/dataStepFour',
                    query: {
                        stepActive: 4,
                        goodClassId: Number(this.$route.query.goodClassId),
                        data_center_tpl_id: Number(this.$route.query.data_center_tpl_id),
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .big-title {
        margin: 40px 0 10px;
        font-size: 16px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .goods-wrapper {
        display: flex;
        align-items: center;
        .goods-cover {
            width: 80px;
            height: 80px;
            display: flex;
            background: #F6F6F6;
            img  {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
        .goods-name {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            font-size: 16px;
        }
    }
    .demo-ruleForm {
        .el-form-item {
            margin-bottom: 16px;
        }
        ::v-deep .el-form-item__label {
            font-size: 16px;
            color: #333;
        }
        ::v-deep .el-form-item__error {
            padding-top: 2px;
        }
    }
</style>